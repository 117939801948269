import React, { useState } from "react";
import styled, { css } from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import {
  makeStyles,
  Button,
  Grid,
  Paper,
  Typography,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import AWSService from "../../../services/AWSService";
import { getErrorMessage, getMimeType } from "../../../helpers/functions";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import CaseService from "../../../services/CaseService";
import { CheckboxContainer } from "../../common/FormInputs";
import JSZip from "jszip";

let validFileFormat = [
  "pdf",
  "xlsx",
  "png",
  "jpeg",
  "text",
  "docx",
  "xls",
  "tiff",
  "mp3",
];

const validFormat = [
  "application/pdf", // .pdf
  "image/png", // .png
  "image/jpeg", // .jpeg, .jpg
  "image/tiff",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "video/mp4",
  "audio/mpeg",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "text/csv",
  "text/plain",
];

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: COLORS.PRIMARY_WHITE,
    boxShadow: "none",
    width: "77px",
    height: "30px",
    color: COLORS.BTN_GREEN,
    textTransform: "Capitalize",
    border: "solid 1px #00838c",
    fontFamily: theme.fonts.primaryFontBold,
    fontSize: 14,
  },
  formLabel: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.COLOR_DARK,
  },
}));

const BulkUpload = ({ bulkUpload, setBulkUpload }) => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [url, setUrl] = useState([]);
  const [view, setView] = useState("me");
  const [invalidFile, setInvalidFile] = useState([]);
  const [open, setOpen] = useState(false);
  const [folderType, setFolderType] = useState("folder");

  /*
 
  async function s3Upload(file) {
    const selectedFiles = Array.from(file);
    const validFiles = [];
    const validFormatFiles = [];

    let webkitdirectoryName;
    selectedFiles.forEach((file) => {
      if (validFormat.includes(file.type)) {
        if (file.webkitRelativePath) {
          webkitdirectoryName = file.webkitRelativePath.split("/");
          webkitdirectoryName =
            webkitdirectoryName[webkitdirectoryName.length - 2];
        }
        validFiles.push(`${webkitdirectoryName}/${file?.name}`);
        const newFileName = `${webkitdirectoryName}/${file?.name}`;
        const modifiedFile = new File([file], newFileName, {
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
          name: newFileName,
          type: file.type,
          size: file?.size,
          webkitRelativePath: file?.webkitRelativePath,
        });
        validFormatFiles.push(modifiedFile);
      } else {
      }
    });

    const chunkArray = (arr, size) =>
      arr.reduce(
        (acc, _, i) =>
          i % size === 0 ? [...acc, arr.slice(i, i + size)] : acc,
        [],
      );

    const batches = chunkArray(validFormatFiles, 50);
    if (!batches.length) {
      const errorMessage = "Please upload valid file format";
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
    }
    for (let i = 0; i < batches.length; i++) {
      const currentBatch = batches[i];
      try {
        setLoader({ state: true, message: `Uploading file...` });
        const payload = {
          key: currentBatch.map((item) => item.name),
        };
        // Upload all files in the current batch in parallel
        const s3Response = await AWSService.getS3URL(payload);
        const s3url = [];
        for (let i = 0; i < currentBatch.length; i++) {
          const res = await AWSService.uploadToS3(
            s3Response?.url[i],
            currentBatch[i],
          );
          s3url.push(res);
        }
        setUrl((prevUrl) => [...prevUrl, ...s3url]);
        setBatches(`batch ${i + 1} of total ${batches.length}`);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  */

  const bulkUploadDocuments = async (url) => {
    try {
      setLoader({ state: true, message: "please wait..." });
      const payload = {
        url: {
          url,
        },
        visibilityType: view,
      };
      const response = await CaseService.bulkUploadDocuments(payload);
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setBulkUpload(false);
      setUrl([]);
    }
  };

  const action_buttons = [
    {
      label: "Close",
      props: {
        onclick: () => setBulkUpload(false),
      },
      button: "secondary",
      disabled: false,
    },
    {
      label: "Next",
      props: {
        onclick: () => setOpen(true),
      },
      button: "primary",
      disabled: false,
    },
  ];

  const confirm_buttons = [
    {
      label: "Back",
      props: {
        onclick: () => setOpen(false),
      },
      button: "secondary",
      disabled: false,
    },
    {
      label: "Submit",
      props: {
        onclick: () => bulkUploadDocuments(url),
      },
      button: "primary",
      disabled: false,
    },
  ];

  const viewing_items = [
    {
      label: "Share with other parties, CM and Neutral",
      name: "all",
      value: "all",
    },
    {
      label: "Share with Neutral",
      name: "others",
      value: "others",
    },
    {
      label: "Private Viewing Only",
      name: "me",
      value: "me",
    },
  ];

  /**
   * @description folder selection
   */

  const handleFolderSelection = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    setInvalidFile([]);
    // Group files into folders
    const folderStructure = selectedFiles.reduce((acc, file) => {
      const folderName = file.webkitRelativePath.split("/")[1]; // Adjust index for deeper structures
      if (!acc[folderName]) acc[folderName] = [];
      acc[folderName].push(file);
      return acc;
    }, {});

    // Start uploading files immediately
    await uploadFiles(folderStructure);
  };

  /**
   * @description split folder
   */

  const uploadFiles = async (folderStructure) => {
    for (const [folderName, files] of Object.entries(folderStructure)) {
      for (const file of files) {
        let webkitdirectoryName;
        if (validFormat.includes(file.type)) {
          if (file.webkitRelativePath) {
            webkitdirectoryName = file.webkitRelativePath.split("/");
            webkitdirectoryName =
              webkitdirectoryName[webkitdirectoryName.length - 2];
          }
          const newFileName = `${webkitdirectoryName}/${file?.name}`;
          const modifiedFile = new File([file], newFileName, {
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            name: newFileName,
            type: file.type,
            size: file?.size,
            webkitRelativePath: file?.webkitRelativePath,
          });
          await s3Upload(modifiedFile, folderName);
        } else {
          setInvalidFile((prevState) => [...prevState, file?.name]);
        }
      }
    }
    enqueueSnackbar("All files uploaded successfully!", {
      variant: "success",
    });
  };

  /**
   * @description upload files
   */

  const s3Upload = async (file, folderName) => {
    try {
      setLoader({ state: true, message: `Uploading folder: ${folderName}` });
      const payload = {
        key: file?.name,
      };
      // Upload all files in the current batch in parallel
      const s3Response = await AWSService.getS3URL(payload);
      if (s3Response.url) {
        const s3url = [];
        const res = await AWSService.uploadToS3(s3Response?.url, file);
        s3url.push(res);
        setUrl((prevUrl) => [...prevUrl, ...s3url]);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, { variant: "error" });
    } finally {
      setLoader({ state: false });
    }
  };

  /**
   * @description zip file upload
   * @param {*} event
   */

  const handleZipUpload = async (event) => {
    setLoader({ state: true, message: "Please wait extracting zip files..." });
    const file = event.target.files[0];
    setUrl([]);
    setInvalidFile([]);
    if (file && file.name.endsWith(".zip")) {
      const zip = new JSZip();
      const zipContent = await zip.loadAsync(file);

      const folderWiseFiles = {};
      for (const relativePath in zipContent.files) {
        const fileData = zipContent.files[relativePath];
        if (!fileData.dir) {
          const folder = relativePath.includes("/")
            ? relativePath.split("/")[0]
            : "root";
          const fileBlob = await fileData.async("blob");

          if (!folderWiseFiles[folder]) folderWiseFiles[folder] = [];
          folderWiseFiles[folder].push(new File([fileBlob], fileData.name));
        }
      }

      const validFiles = [];
      const validFormatFiles = [];

      let webkitdirectoryName;
      let fileName;

      const chunkArray = (arr, size) =>
        arr.reduce(
          (acc, _, i) =>
            i % size === 0 ? [...acc, arr.slice(i, i + size)] : acc,
          [],
        );

      // upload folder wise

      for (const folder in folderWiseFiles) {
        const files = folderWiseFiles[folder];
        for (const file in files) {
          let fileLength = files[file].name.split(".");
          let newFileName = "";
          if (
            validFileFormat.includes(
              files[file].name.split(".")[fileLength.length - 1],
            )
          ) {
            if (files[file]) {
              webkitdirectoryName = files[file].name.split("/");
              fileName = webkitdirectoryName[webkitdirectoryName.length - 1];
              webkitdirectoryName =
                webkitdirectoryName[webkitdirectoryName.length - 2];
            }
            if (webkitdirectoryName !== undefined) {
              validFiles.push(`${webkitdirectoryName}/${fileName}`);
              newFileName = `${webkitdirectoryName}/${fileName}`;
            } else {
              validFiles.push(`${fileName}`);
              newFileName = `${fileName}`;
            }
            const modifiedFile = new File([files[file]], newFileName, {
              lastModified: files[file].lastModified,
              lastModifiedDate: files[file].lastModifiedDate,
              name: newFileName,
              type: getMimeType(fileLength[fileLength.length - 1]),
              size: files[file]?.size,
              webkitRelativePath: files[file]?.webkitRelativePath,
            });
            validFormatFiles.push(modifiedFile);
          } else {
            setInvalidFile((prevState) => [...prevState, files[file]?.name]);
          }
        }
      }

      // split batch wise per batch it should allow 50 files

      const batches = chunkArray(validFormatFiles, 50);

      if (!batches.length) {
        const errorMessage = "Please upload valid file format";
        setLoader({ state: false });
        enqueueSnackbar(errorMessage, {
          variant: "error",
        });
      } else {
        setLoader({ state: false });
      }

      for (let i = 0; i < batches.length; i++) {
        const currentBatch = batches[i];
        try {
          setLoader({
            state: true,
            message: `Uploading batch ${i + 1} of total ${batches.length}`,
          });
          const payload = {
            key: currentBatch.map((item) => item.name),
          };
          // Upload all files in the current batch in parallel
          const s3Response = await AWSService.getS3URL(payload);
          const s3url = [];
          for (let i = 0; i < currentBatch.length; i++) {
            const res = await AWSService.uploadToS3(
              s3Response?.url[i],
              currentBatch[i],
            );
            s3url.push(res);
          }
          setUrl((prevUrl) => [...prevUrl, ...s3url]);
        } catch (error) {
          const message = getErrorMessage(error);
          enqueueSnackbar(message, { variant: "error" });
        } finally {
          setLoader({ state: false });
        }
      }
    }
  };

  return (
    <Modal
      open={bulkUpload}
      onClose={() => setBulkUpload(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer>
        <Container>
          <Paper elevation={3} style={{ padding: "16px" }}>
            <HeadingContainer>
              <Heading>Bulk Upload</Heading>
            </HeadingContainer>
            {open ? (
              <>
                <Grid
                  item
                  xs={12}
                  spacing={2}
                  style={{
                    marginTop: "16px",
                    padding: 16,
                  }}
                >
                  <Description>
                    {invalidFile?.length
                      ? `Below files not uploaded because of format issue? you still
                    want to proceed with this?`
                      : `Are you sure you want upload this files?`}
                  </Description>
                  {invalidFile?.length ? (
                    <div style={{ height: 110, overflow: "auto" }}>
                      <FilesContainer>
                        {invalidFile.map((file, index) => (
                          <FileItems key={index}>{`${
                            index + 1
                          }. ${file}`}</FileItems>
                        ))}
                      </FilesContainer>
                    </div>
                  ) : null}
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: "16px", padding: 16 }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    {confirm_buttons.map((button) =>
                      button.button === "primary" ? (
                        <PrimaryCTAButton
                          style={{ marginLeft: 8, padding: "10px 10px" }}
                          onClick={() => button.props.onclick()}
                          disabled={!url?.length}
                        >
                          {button.label}
                        </PrimaryCTAButton>
                      ) : (
                        <PrimaryOutlinedCTAButton
                          style={{ marginLeft: 8, padding: "10px 10px" }}
                          onClick={() => button.props.onclick()}
                        >
                          {button.label}
                        </PrimaryOutlinedCTAButton>
                      ),
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  spacing={2}
                  style={{ marginTop: "16px", padding: 16 }}
                >
                  <CheckboxContainer
                    style={{
                      paddingLeft: 10,
                      paddingBottom: 5,
                    }}
                  >
                    <RadioGroup
                      className={classes.radioGroup}
                      style={{ flexDirection: "row" }}
                      value={folderType}
                      onChange={(e) => setFolderType(e.target.value)}
                    >
                      {[
                        { label: "Select a folder", value: "folder" },
                        { label: "Select a zip file", value: "zipfile" },
                      ].map((radio, key) => (
                        <FormControlLabel
                          {...{ key }}
                          classes={{ label: classes.formLabel }}
                          value={radio.value}
                          control={
                            <Radio size="medium" style={{ color: "#7FA1C3" }} />
                          }
                          label={radio.label}
                        />
                      ))}
                    </RadioGroup>
                  </CheckboxContainer>
                  <RadioGroup
                    className={classes.radioGroup}
                    style={{ flexDirection: "column", marginLeft: "10px" }}
                    value={view}
                    onChange={(e) => setView(e.target.value)}
                  >
                    {viewing_items.map((radio, key) => (
                      <FormControlLabel
                        {...{ key }}
                        classes={{ label: classes.formLabel }}
                        value={radio.value}
                        control={
                          <Radio size="medium" style={{ color: "#00838c" }} />
                        }
                        label={radio.label}
                      />
                    ))}
                  </RadioGroup>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: "16px", padding: 16 }}
                >
                  <Label>
                    Document (pdf, mp3, mp4, docx, csv, text, xlsx, xls,
                    image/*)
                  </Label>
                  <FileContainer>
                    <FileName>
                      {url?.length ? `${url.length} Files` : "Select a file"}
                    </FileName>
                    <Button
                      disableElevation
                      className={classes.button}
                      variant="contained"
                      component="label"
                    >
                      Browse
                      {folderType === "zipfile" ? (
                        <input
                          type="file"
                          accept="application/zip"
                          multiple
                          style={{ display: "none" }}
                          onChange={handleZipUpload}
                        />
                      ) : (
                        <input
                          type="file"
                          webkitdirectory="true"
                          multiple
                          style={{ display: "none" }}
                          onChange={handleFolderSelection}
                          // onChange={(event) => s3Upload(event?.target?.files)}
                        />
                      )}
                    </Button>
                  </FileContainer>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: "16px", padding: 16 }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    {action_buttons.map((button) =>
                      button.button === "primary" ? (
                        <PrimaryCTAButton
                          style={{ marginLeft: 8, padding: "10px 10px" }}
                          onClick={() => button.props.onclick()}
                          disabled={!url?.length}
                        >
                          {button.label}
                        </PrimaryCTAButton>
                      ) : (
                        <PrimaryOutlinedCTAButton
                          style={{ marginLeft: 8, padding: "10px 10px" }}
                          onClick={() => button.props.onclick()}
                        >
                          {button.label}
                        </PrimaryOutlinedCTAButton>
                      ),
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        </Container>
      </DrawerContainer>
    </Modal>
  );
};

export default BulkUpload;

const FileContainer = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width + "px" : "436px")};
  background-color: ${COLORS.PRIMARY_WHITE};
  height: 50px;
  border-radius: 6px;
  border: solid 0.5px ${COLORS.INPUT_BORDER};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
  padding: 10px 12px;
  ${({ error }) =>
    error &&
    css`
      border: solid 0.5px #f44336;
    `}
`;

const FileName = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  letter-spacing: 0.1px;
  color: ${COLORS.COLOR_DARK};
`;

const Container = styled.div`
  padding: 34px 43px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .delete {
    display: flex;
    cursor: pointer;
  }
  @media ${theme?.breakpoints?.sm_up} {
  }
`;

const Heading = styled(Typography)(() => ({
  backgroundColor: "#B3C8CF",
  color: "#fff",
  padding: "8px",
  fontFamily: theme.fonts.primaryFontBold,
  borderRadius: "5px",
}));

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 516px;
  }
`;

const HeadingContainer = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 10px;
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.7;
  color: ${COLORS.INPUT_LABEL};
`;

const Description = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.71;
  color: ${COLORS.COLOR_DARK};
  text-align: center;
  width: 100%;
  min-width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 14px;
    min-width: 440px;
  }
`;

const FilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
`;

const FileItems = styled.div`
  padding: 3px;
  color: #2e8a99;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;
